<template>
    <div class="lonlat-base">
        <mapDraw ref="myMapDraw" :basicMap="taskMap" :drawConf="drawConf" :jsonStr="zuobiao" @update:jsonStr="getJsonStr"></mapDraw>
        <Mymap ref="myMap" class="lonlat-map" :showSwitch="false" @initmap="initMap" :showTool="false"></Mymap>
    </div>
</template>
<script>
import mapDraw from "@comp/map/mapDraw/MapDraw";
export default {
    data() {
        return {
            taskMap: null,
            drawConf: {
                show: true,
                draw: {
                    polygon: false,
                    polyline: false
                }
            },
            zuobiao: "",
        }
    },
    props:{
        dataSource:{
            type: Object,
            default: () => {}
        }
    },
    components:{
        mapDraw
    },
    watch:{
        dataSource() {
            console.log(1, 'watch:', this.dataSource);
            this.handleDataSource();
        }
    },
    mounted(){
        console.log(1, 'mounted:', this.dataSource);
        this.handleDataSource();
    },
    methods:{
        handleDataSource(){
            this.zuobiao = '';
            this.areaLayers.clearLayers();
            if(this.dataSource && this.dataSource.pointValue) {
                if(this.dataSource.pointValue){
                    let pointArr = this.dataSource.pointValue.split(",");
                    let JD = pointArr[0];
                    let WD = pointArr[1];
                    this.zuobiao = `[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[${JD},${WD}]}}]`;
                    this.taskMap.setView([WD, JD], 14);
                } else {
                    this.areaLayers.clearLayers();
                }
            }
        },
        // 地图选点
        initMap(map) {
            this.taskMap = map;
            // eslint-disable-next-line no-undef
            let areaLayers = L.featureGroup().addTo(this.taskMap);
            this.areaLayers = areaLayers;
            this.initZone();
        },
        initZone() {
            // 渲染点位
            let _this = this;
            this.areaLayers.clearLayers();
            if (!this.jsonStr) return false;
            let geoJson = JSON.parse(this.jsonStr);
            // eslint-disable-next-line no-undef
            L.geoJSON(geoJson, {
                onEachFeature: function(feature, layer) {
                    _this.areaLayers.addLayer(layer);
                },
                // eslint-disable-next-line no-unused-vars
                style(feature) {
                    return {
                        color: "#1862ad",
                        fillColor: "#1862ad",
                        weight: 2
                    };
                }
            });
        },
        // 标绘操作
        // eslint-disable-next-line no-unused-vars
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            if (json.type !== "Point") return false;
            this.zuobiao = JSON.stringify([jsonStr[jsonStr.length - 1]]);
            let AZDJD = json.coordinates[0];
            let AZDWD = json.coordinates[1];
            let mapPointData = {
                pointArr: [AZDJD,AZDWD],
                type: this.dataSource.type,
                index: this.dataSource.index
            }
            this.$emit("handleMapPointClick", mapPointData);
        },
    }
}
</script>
<style lang="scss" scoped>
.lonlat-base {
    position: relative;
    width: calc(100% + 40px);
    height: 600px;
    margin: -20px;
    .lonlat-map {
        width: 100%;
        height: 100%;
    }
}

</style>
